.App {
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image:
    linear-gradient(to bottom, #fafafa70, white),
    url("./bg.png");
  background-color: #fafdffff;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  min-height: 100vh;
}

.App-header-dev {
  background-image:
    linear-gradient(to bottom, #fafafa70, white);
  background-color: #fafdffff;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

.nav {
  background-image: linear-gradient(135deg, #f1eff7ff, #f1eff7ff, #f1eff7ff);
  z-index: 2000 !important;
}

.hr-line {
  width: 45%;
  position: relative;
  top: -4px;
  margin: 2px;
  border-bottom: 1px solid black;
}

.hr-icon {
  position: relative;
  top: 0px;
  line-height: 2px;
  font-size: .75em;
  color: black;
}

.site-footer {
  background-color: #464977;
  margin-top: -25px;
  border-radius: 30px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  color: floralwhite;
  font-size: .93em;
}

.custom-datepicker .react-datepicker__input-container input {
  width: 100%;
}

.odd-row {
  border-radius: 10px;
  padding: 5px;
  padding-top: 1px;
  padding-left: 20px;
  background-color: rgba(132, 245, 192, 0.644);
}

.even-row {
  border-radius: 10px;
  padding: 5px;
  padding-top: 1px;
  padding-left: 20px;
  background-color: rgba(205, 237, 255, 0.644);
}

.uni-row {
  border-radius: 10px;
  padding: 5px;
  padding-top: 1px;
  padding-left: 20px;
  background-color: rgba(218, 218, 218, 0.644);
}

.main-container {
  margin-top: 30px;
}

.sidebar-nav {
  width: 60px;
  z-index: 1999 !important;
}

.login-pan {
  border: 1px solid gray;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 100px;
}

.login-pan h3 {
  border-bottom: 1px solid gray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #749fe0;
  padding: 10px;
}

.login-pan .input-items {
  margin: 20px;
}

.login-pan .input-items * {
  display: block;
  margin: 5px;
}

.landingBtns {
  width: 230px !important;
}

.hover-icon {
  position: absolute !important;
  margin-left: -43px;
  margin-top: -8px;
  color: tomato;
}

#overlay-ex {
  z-index: 98 !important;
}

.hd-mask {
  background-color: rgba(255, 150, 155, 0.6) !important;
  padding: 5px;
  border-radius: 10px;
}

.filter-box {
  display: flex;
}

/*@media section*/
@media only screen and (max-width: 680px) {
  .main-container {
    margin-top: 80px;
  }

  .sidebar-nav {
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 580px) {
  .site-title {
    font-size: 1em;
  }

  .site-subtitle {
    font-size: .65em;
  }

  .hr-icon {
    font-size: .6em;
  }

  .site-footer {
    font-size: .7em;
  }

  .sidebar-nav {
    width: 80%;
    margin: 0 auto;
  }
}


@media only screen and (max-width: 450px) {
  .site-title {
    font-size: .7em;
  }

  .site-subtitle {
    font-size: .45em;
  }

  .hr-icon {
    font-size: .3em;
  }

  .sidebar-nav {
    width: 80%;
    margin: 0 auto;
  }

  .filter-box {
    display: block;
  }
}